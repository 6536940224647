import * as React from "react"

const CategoryFilterButtons = ({ filterItems, sortedCategories, selectedCategory }) => {
    return (
        <div className="categoryFilterButtons">
            {sortedCategories.map((category, index) => (
                <button key={index} className={selectedCategory === category ? "active" : ""} onClick={() => filterItems(category)}><p>{category}</p></button>
            ))}
        </div>
    )
}

export default CategoryFilterButtons