import React, { useState } from "react"
import { graphql, Link } from 'gatsby'
import Seo from "../../components/seo"
import PortfolioItem from "../../components/portfolioItem"
import CategoryFilterButtons from "../../components/categoryFilterButtons"
import { motion } from "framer-motion"

const WorkPage = (props) => {
    const nodes = props.data.contentfulPortfolioOrder.order
    const [items, setItems] = useState(nodes)
    const [selectedCategory, setSelectedCategory] = useState("All")

    const mergedUniqueCategories = new Set([].concat.apply([], nodes.map((item) => item.category)))
    let sortedCategories = Array.from(mergedUniqueCategories).sort()
    sortedCategories.unshift("All")

    const filterItems = (currentCategory) => {
        if (currentCategory === "All") {
            setSelectedCategory("All")
            setItems(nodes)
        } else {
            const newItems = nodes.filter((item) => {
                return item.category.includes(currentCategory)
            })
            setSelectedCategory(currentCategory)
            setItems(newItems)
        }
    }

    let firstColumnItems = []
    let secondColumnItems = []
    let thirdColumnItems = []

    let randomDelays = []
    const delayMultiplier = 0.03

    for (let i = 0; i < items.length; i++) {
        if (i === 0 || i % 3 === 0){
            firstColumnItems.push(items[i])
        } else if (i === 1 || i % 3 === 1 ) {
            secondColumnItems.push(items[i])
        } else {
            thirdColumnItems.push(items[i])
        }

        randomDelays.push(i * delayMultiplier)
    }

    randomDelays = shuffle(randomDelays)

    const WorkColumn = ({ items, offset }) => (
        <div className="column">
            {items.map((item, index) => {
                const randomDelay = randomDelays[(index + offset)]
                return (<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: randomDelay, duration: 0.5, type: "tween" }} key={item.id}>
                    <PortfolioItem item={item} />
                </motion.div>
                )
            })}
        </div>
    )

    return (
        <div id="container" className="workPage">
            <Seo title="Work" />
            <h1 className="main myname"><Link to="/">david adam roth</Link></h1>

            <CategoryFilterButtons filterItems={filterItems} sortedCategories={sortedCategories} selectedCategory={selectedCategory} />

            <div className="portfolio-tiles single-column">
                {items.map((item) => (
                    <PortfolioItem item={item} key={item.id} />
                ))}
            </div>

            <div className="portfolio-tiles multi-column">
                <WorkColumn items={firstColumnItems} offset={0} />
                <WorkColumn items={secondColumnItems} offset={firstColumnItems.length} />
                <WorkColumn items={thirdColumnItems} offset={firstColumnItems.length + secondColumnItems.length} />
            </div>
        </div>
    )
}

export default WorkPage

export const query = graphql`
    query allWorks {
        contentfulPortfolioOrder {
        order {
            id
            category
            title
            brand
            thumbnail {
            gatsbyImageData
            }
            workPath: gatsbyPath(filePath: "/work/{ContentfulPortfolioPiece.title}")
            videoURL
        }
        }
    }  
`

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }