import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const PortfolioItem = (props) => {
    const { item } = props

    return (
        <Link to={item.workPath} className="portfolio-tile">
            <GatsbyImage image={getImage(item.thumbnail)} alt={item.title} />
            <div className="work-info">
                <h5 className="brand">{item.brand}</h5>
                <h6 className="title">{item.title}</h6>
            </div>
        </Link>
    )
}

export default PortfolioItem